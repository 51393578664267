import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Day`}</em></p>
    <p>{`Skill Practice: Pistols`}</p>
    <p>{`Then,`}</p>
    <p>{`150ft Bear Crawl Buy In`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`25 KBS’s (53/35)`}</p>
    <p>{`20 T2B`}</p>
    <p>{`15 Burpees`}</p>
    <p>{`10 Pistols (5/leg)`}</p>
    <p>{`5 Wall Walks`}</p>
    <p>{`800M Run Cash Out.`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday we’ll have a free New Years Eve workout at 10:00am at
The Ville!  All other classes at both locations are cancelled.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar will Dawn from Sports Nutrition 2 Go in Cincinnati. Metabolic
Testing will be offered before and after the seminar as well!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      